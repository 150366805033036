<script lang="ts">
	import UploadIcon from '$icons/upload-icon.svelte';
	import ContactFormConfirmation from './ContactFormConfirmation.svelte';
	import * as Sentry from '@sentry/sveltekit';
	import { recaptchaKey } from '$lib/config/system';
	import { Circle } from 'svelte-loading-spinners';
	import { openModal } from 'svelte-modals';
	import { onMount } from 'svelte';

	export let type: 'sales' | 'recruitment' = 'sales';
	export let title = 'Get in touch!';
	export let description =
		'Have a product idea in your mind? Drop us a line, and we’ll get back to you promptly.';
	export let action = 'Send request';
	export let showAsH1: true|false = false;

	const minRows = 3;
	const maxRows = 10;
	let name = '';
	let email = '';
	let message = '';
	let files: FileList;
	let fileList: File[] = [];
	let newsUpdatesConsent = false;
	let personalDataConsent = true;

	const toBase64 = (file: File): Promise<string | ArrayBuffer | null> =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const showSuccessModal = () => {
		openModal(ContactFormConfirmation, {
			title: 'Thank you for reaching out!',
			message: 'We’ll get back to you shortly!'
		});
	};

	const showErrorModal = () => {
		openModal(ContactFormConfirmation, {
			title: 'We had an error while sending your email',
			message: 'Please try to send it once again'
		});
	};

	const fileListChanged = () => {
		fileList = Array.from(files);
	};

	const removeFile = (i: number) => {
		fileList = fileList.filter((_, index) => index !== i);
	};

	async function submitForm() {
		if (loading) {
			return;
		}

		loading = true;

		const content = message.replace(new RegExp('\r?\n', 'g'), '<br />');
		const gaAnalyticsCookieMatch = document?.cookie?.match(/_ga=(.+?);/);
		const googleAnalyticsId =
			!!gaAnalyticsCookieMatch && gaAnalyticsCookieMatch.length > 0
				? gaAnalyticsCookieMatch[1].split('.').slice(-2).join('.')
				: '';
		const referrer = sessionStorage.getItem('referrer');
		const landing = sessionStorage.getItem('landing');
		const sentFromUrl = window.location.href;

		const baseLogMessage = {
			email,
			name,
			type,
			googleAnalyticsId: googleAnalyticsId || 'N/A',
			referrer: referrer || 'N/A',
			landing: landing || 'N/A',
			sentFromUrl: sentFromUrl || 'N/A',
			receiveNews: newsUpdatesConsent ? 'Yes' : 'No',
			personalDataConsent: personalDataConsent ? 'Yes' : 'No'
		};

		try {
			grecaptcha.ready(async () => {
				const token = await grecaptcha.execute(recaptchaKey, { action: 'submit' });
				if (!token) {
					loading = false;

					Sentry.captureMessage('ReCaptcha failed', (scope) => {
						scope.clear();
						scope.setTags(baseLogMessage);
						scope.setLevel('info');
						scope.setExtra('message', content);
						return scope;
					});

					return;
				}

				const validateCaptcha = await fetch(`/.netlify/functions/validateCaptcha?rt=${token}`, {
					method: 'GET'
				});

				if (!validateCaptcha.ok) {
					loading = false;

					Sentry.captureMessage('Failed ReCaptcha response', (scope) => {
						scope.clear();
						scope.setTags(baseLogMessage);
						scope.setLevel('info');
						scope.setExtra('message', content);
						return scope;
					});

					return;
				}

				const attachments = await Promise.all(
					fileList.map(async (item) => {
						const content = await toBase64(item);
						return {
							filename: item.name,
							type: item.type,
							content: (content as string).split(',')[1]
						};
					})
				);

				Sentry.captureMessage('Got new Email', (scope) => {
					scope.clear();
					scope.setTags(baseLogMessage);
					scope.setLevel('info');
					scope.setExtra('message', content);
					return scope;
				});

				const handleFunctionUrl = 'https://exoft-email.azurewebsites.net/api/handle-request';
				const submit = await fetch(handleFunctionUrl, {
					method: 'POST',
					mode: 'cors',
					headers: {
						'Content-Type': 'application/json',
						'Access-Control-Allow-Origin': '*',
						Accept: 'application/json'
					},
					body: JSON.stringify({ message: content, email, name, attachments, type, sentFromUrl: window.location.href })
				});

				if (submit?.ok) {
					name = '';
					email = '';
					message = '';
					fileList = [];
					const formElement = document.getElementById('form') as HTMLFormElement;
					if (formElement) {
						formElement.reset();
						newsUpdatesConsent = true;
						personalDataConsent = true;
					}

					showSuccessModal();
					loading = false;

					Sentry.captureMessage('Got new Email', (scope) => {
						scope.clear();
						scope.setTags(baseLogMessage);
						scope.setLevel('info');
						scope.setExtra('message', content);
						return scope;
					});

					return;
				}

				const errorText = await submit.text();
				Sentry.captureMessage('Failed send email request', (scope) => {
					scope.clear();
					scope.setTags(baseLogMessage);
					scope.setLevel('error');
					scope.setExtra('message', content);
					scope.setExtra('error', errorText);
					return scope;
				});

				showSuccessModal();
				loading = false;
			});
		} catch (err) {
			Sentry.captureException(err, (scope) => {
				scope.clear();
				scope.setTags(baseLogMessage);
				scope.setLevel('error');
				scope.setExtra('message', content);
				return scope;
			});
		}
	}

	onMount(() => {
		if (typeof IntersectionObserver === 'undefined') {
			return;
		}

		let observer = new IntersectionObserver(
			(entries, observer) => {
				entries.forEach((entry) => {
					if (!entry.isIntersecting) {
						return;
					}

					const htmlTarget = entry.target as HTMLImageElement;
					htmlTarget.src = htmlTarget.dataset.src || '';
					htmlTarget.classList.remove('lazyload');
					observer.unobserve(htmlTarget);
				});
			},
			{ threshold: 0, rootMargin: '20px' }
		);

		document.querySelectorAll(`.lazyload`).forEach((entry) => observer.observe(entry));
	});

	$: minHeight = `${1 + minRows * 1.2}em`;
	$: maxHeight = maxRows ? `${1 + maxRows * 1.2}em` : `auto`;
	$: emailFormDisabled = !email || !message || !name || loading || !personalDataConsent;
	$: loading = false;
</script>

<article class="container">
	<div class="section-title flex-column-center">
		{#if showAsH1}
			<h1>{@html title}</h1>
		{:else }
			<h2>{@html title}</h2>
		{/if}
	</div>

	<p class="h5">{@html description}</p>

	<div class="form-container flex-row">
		<form id="form" class="form flex-column" on:submit|preventDefault="{submitForm}">
			<label class="flex-column">
				Name
				<input name="name" type="text" placeholder="" bind:value="{name}" />
			</label>

			<label class="flex-column">
				Email
				<input name="email" type="email" placeholder="" bind:value="{email}" />
			</label>

			<label class="flex-column">
				{type === 'recruitment' ? 'Tell us about you' : 'Tell us about your project'}
				<div class="text-area-container">
					<pre aria-hidden="true" style="min-height: {minHeight}; max-height: {maxHeight}">
          {message + '\n'}
        </pre>

					<textarea name="message" placeholder="" bind:value="{message}"></textarea>
				</div>
			</label>

			<div class="flex-row footer">
				<div class="upload-file-block">
					<label for="upload" id="upload_link">
						<UploadIcon />
						<span class="upload-file-text">Attach files</span>
					</label>

					{#if fileList?.length > 0}
						<div class="files-list">
							{#each fileList as file, i}
								<div class="files-list-item">
									{file.name}
									<button type="button" on:click="{() => removeFile(i)}" class="delete-button"
										>x</button>
								</div>
							{/each}
						</div>
					{/if}
				</div>

				<input
					id="upload"
					type="file"
					multiple
					bind:files="{files}"
					on:change="{() => fileListChanged()}" />
			</div>

			<div class="flex-column">
				<div class="accept-policy">
					<input
						type="checkbox"
						id="newsUpdates"
						name="newsUpdates"
						bind:checked="{newsUpdatesConsent}" />
					<label for="newsUpdates">
						I would like to receive news and updates from Exoft once in a while
					</label>
				</div>

				<div class="accept-policy">
					<input
						type="checkbox"
						id="personalData"
						name="personalData"
						bind:checked="{personalDataConsent}" />
					<label for="personalData">
						I give consent to the processing of my personal data given in the contact form above as
						well as receiving commercial and marketing communications under the terms and conditions
						of Exoft <a href="/privacy-policy/" class="inline-link" target="_blank"
							>Privacy Policy.</a>
					</label>
				</div>
			</div>

			<button disabled="{emailFormDisabled}" type="submit" class="action-button">
				{action}
				{#if loading}
					<div class="spinner">
						<Circle size="36" color="#69B518" unit="px" />
					</div>
				{/if}
			</button>
		</form>

		<div class="img"></div>
	</div>
</article>

<style lang="scss">
	@import '$styles/vars';

	.section-title{
	  h1, h2 {
		margin: 0;
		padding: 0;
		font-size: 48px;
		font-weight: 600;
		text-align: center;
		color: $main-black;
	  }
	}

	.h5 {
		margin: 12px 0;
	}

	article {
		align-items: center;
		display: flex;
		flex-direction: column;

		.form-container {
			margin-top: 48px;
			justify-content: space-between;
			column-gap: 4%;
			width: 100%;
			box-sizing: border-box;

			.form {
				padding: 36px 24px;
				min-width: 55%;
				border-radius: 12px;
				background: $light-grey;
			}
			.img {
				background: url(/contact.webp) center/cover no-repeat;
				border-radius: 12px;
				flex: 1 1 40%;
				min-width: 30%;
			}

			.form {
				row-gap: 12px;
				margin-bottom: 0;

				.accept-policy {
					display: flex;
					align-items: flex-start;
					font-size: 0;

					input {
						position: relative;
						border: 1px solid $green;
						border-radius: 6px;
						background: none;
						cursor: pointer;
						line-height: 0;
						margin: 4px 1em 0 0;
						outline: 0;
						padding: 0 !important;
						vertical-align: text-top;
						height: 24px;
						width: 24px;
						-webkit-appearance: none;
						opacity: 1;
						min-width: 24px;
						min-height: 24px;

						&:hover {
							opacity: 1;
						}

						&:checked {
							background-color: $green;
							opacity: 1;
						}

						&:checked::before {
							content: '';
							position: absolute;
							right: 50%;
							top: 50%;
							width: 4px;
							height: 10px;
							border: solid $main-white;
							border-width: 0 2px 2px 0;
							margin: -1px -1px 0 -1px;
							transform: rotate(45deg) translate(-50%, -50%);
							z-index: 2;
						}
					}

					&:not(:first-child) {
						margin-top: 4px;
					}

					label {
						color: $main-black;
					}
				}

				label {
					font-style: normal;
					font-size: 14px;
					line-height: 28px;
					color: $main-black;

					textarea,
					input {
						background: transparent;
						outline: none;
						box-shadow: none;
						border-radius: 12px;
						border: 0.6px solid $dark-grey;
						padding: 10px 12px;
						color: $main-black;
						font-family: 'Fixel Text', sans-serif;
						font-display: swap;
						&:focus {
			  			border-color: $green;
						}
					}

					input::placeholder,
					textarea::placeholder {
						font-family: 'Fixel Text', sans-serif;
						color: $border-color;
						font-display: swap;
					}

					.text-area-container {
						position: relative;

						pre,
						textarea {
							overflow: hidden;
						}

						pre {
							visibility: hidden;
						}

						textarea {
							position: absolute;
							width: 100%;
							height: 100%;
							top: 0;
							resize: none;
							overflow-y: auto;
						}
					}
				}

				.footer {
					justify-content: space-between;
					align-items: center;

					.upload-file-block {
						display: flex;
						flex-direction: column;

						.files-list {
							display: flex;
							flex-direction: column;
							row-gap: 4px;
							margin-top: 8px;

							&-item {
								font-size: 12px;
								color: $green;

								.delete-button {
									color: $green;
									margin-left: 4px;
									font-weight: 500;
									background-color: transparent;
									border: none;
									outline: none;
									cursor: pointer;
									font-size: 16px;

									&:hover {
										color: $green-accent;
									}
								}
							}
						}

						#upload_link {
							cursor: pointer;
							font-weight: 600;
							font-size: 14px;
							line-height: 28px;
							color: $green;
							display: flex;
							align-items: center;
							font-style: normal;
							column-gap: 12px;

							.upload-file-text {
							}

							&:hover {
								text-decoration: underline;
							}
						}
					}

					#upload {
						display: none;
					}
				}

				.action-button {
					color: $main-black;
					align-self: center;
					margin-top: 36px;
					&:disabled{
						color: $dark-grey;
						background-color: transparent;
						border: 2px solid rgba(105, 181, 24, 0.37);
					}

					.spinner {
						position: absolute;
					}
				}
			}
		}
	}

	@media (min-width: $portrait-phone-size) and (max-width: $tablet-size) {
		article {
			.h5 {
				text-align: center;
			}

			.form-container {
				flex-direction: column;
				padding: 0;

				.footer {
					.action-button {
						padding: 14px 110px;
					}
				}
			}
		}
	}

	@media (max-width: $portrait-phone-size) {
		article {
	  	.section-title{
				h1, h2 {
					font-size: 32px;
				}
	  }
			
			.h5 {
				margin: 0;
				text-align: center;
			}

			.form-container {
				margin-top: 24px;
				flex-direction: column;

				.form {
					padding: 24px 12px;
					margin-bottom: 36px;

					label {
						font-size: 12px;

						textarea,
						input {
							border-radius: 6px;
						}
					}

					.footer {
						.upload-file-block {
							#upload_link {
								font-size: 12px;
							}
						}
					}
				}

				.footer {
					flex-direction: column;
					align-items: flex-start !important;

					.action-button {
						margin-top: 37px;
						width: 100% !important;
					}
				}
			}
		}
	}
</style>
