<script lang="ts">
	import { closeModal } from 'svelte-modals';

	export let isOpen = false;
	export let title: string | '' = '';
	export let message: string | '' = '';

</script>

{#if isOpen}
	<article tabindex="-1" aria-hidden="true" class="root">
		<h2>{title}</h2>

		<p class="h5">{message}</p>

		<button type="button" class="action-button" on:click="{closeModal}" data-bs-dismiss="modal">
			Close
		</button>
	</article>
{/if}

<style lang="scss">
	@import '$styles/vars';

	article {
		display: flex;
		position: fixed;
		bottom: 0;
		top: 0;
		right: 0;
		left: 0;
		flex-direction: column;
		padding: 36px 120px;
		background-color: $light-grey;
		box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
		border-radius: 12px;
		z-index: 200;
		width: fit-content;
		height: fit-content;
		margin: auto;
		align-items: center;
		color: $main-black;
		max-width: 800px;

		h2 {
			font-size: 16px;
			font-style: normal;
			font-weight: 600;
			line-height: 28px;
			margin: 0;
		}

		.h5 {
			font-size: 14px;
			line-height: 28px;
			margin-top: 12px;
			padding: 0;
		}

		.action-button {
			line-height: 22px;
			margin-top: 48px;
			padding: 12px 60px;
			cursor: pointer;
		}
	}

	@media (max-width: $portrait-phone-size) {
		article {
			padding: 36px 60px;
			box-sizing: border-box;
			max-width: 90%;
			max-height: 90%;
			overflow: auto;

			h2 {
				text-align: center;
				line-height: 28px;
			}

			.h5 {
				font-size: 14px;
				line-height: 22px;
				text-align: center;
				margin-bottom: 0;
			}

			.action-button {
			  padding: 12px 40px;
			}

		}
	}
</style>
